<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="教学类目">
          <el-select
            v-model="teaching_categories"
            clearable
            name="categories_child"
            remote
            style="width: 150px"
          >
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="status"
            placeholder="请选择"
            style="width: 100px"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市">
          <citySelect
            :area="true"
            @change="cityChange"
            :checkStrictly="true"
          ></citySelect>
        </el-form-item>

        <el-form-item label="审核人">
          <admin-select :adminid.sync="admin_id"></admin-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table
        :data="DataList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column type="index"></el-table-column>
        <el-table-column label="教练名称" prop="real_name"></el-table-column>
        <el-table-column label="联系电话" prop="coach_phone"></el-table-column>

        <el-table-column
          label="教学类目"
          prop="teaching_categories"
        ></el-table-column>
        <el-table-column label="性别" prop="sex">
          <template slot-scope="{ row }">
            <p v-if="row.coach_sex == 0">未知</p>
            <p v-if="row.coach_sex == 1">男</p>
            <p v-if="row.coach_sex == 2">女</p>
          </template>
        </el-table-column>
        <el-table-column label="提交时间" prop="create_time"></el-table-column>
        <el-table-column
          label="审核时间"
          prop="update_time"
          v-if="timeShow"
        ></el-table-column>
        <el-table-column label="类型">
          <template slot-scope="{ row }">
            <p>{{ typeMap[row.type] }}</p>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="{ row }">
            <el-tag type="success" v-if="row.status == 1">审核通过</el-tag>
            <el-tag type="info" v-if="row.status == 0">待审核</el-tag>
            <el-tag type="danger" v-if="row.status == 2">审核拒绝</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="250px">
          <template slot-scope="{ row }">
            <div class="flex flex-wrap">
              <el-button type="primary" @click="toDetails(row)" size="small"
                >查看详情</el-button
              >
              <el-button
                type="danger"
                v-if="row.status == 2"
                @click="updateStatus(row)"
                size="small"
                >反审核
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="Total"
      ></paging-fy>
    </el-row>
    <el-dialog title="拒绝理由" :visible.sync="dialogVisible" width="30%">
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入内容"
        v-model="remark"
      >
      </el-input>
      <div
        v-for="(item, index) in list"
        :key="index"
        @click="remark = item"
        style="margin-top: 10px; margin-right: 10px"
      >
        {{ item }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="refuse">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
      
<script>
import adminSelect from "../../components/select/adminSelect.vue";
import citySelect from "../../components/select/citySelect.vue";

export default {
  components: {
    adminSelect,
    citySelect,
  },
  data() {
    return {
      nav: { firstNav: "教练管理中心", secondNav: "教练信息审核" },
      DataList: [],
      is_loading: true,
      currentPage: 1,
      list: ["不符合要求"],
      remark: "",
      dialogVisible: false,
      record: [],
      typeMap: {
        regist: "注册",
        update: "修改信息",
      },
      options: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "已通过",
        },
        {
          value: 2,
          label: "已拒绝",
        },
      ],
      phone: "",
      name: "",
      status: 0,
      timeShow: false,
      listPageChild: [],
      coachInfo: "",
      teaching_categories: "",
      Total: 0,
      admin: JSON.parse(localStorage.getItem("managerInfo")),
      admin_id: "",
      cityParam: {},
    };
  },
  mounted() {
    this.getList();
    this.GetListPageChild();
  },
  methods: {
    cityChange(v) {
      console.log(v);
      this.cityParam.province = v[0] || null;
      this.cityParam.city = v[1] || null;
      this.cityParam.district = v[2] || null;
    },
    updateStatus(row) {
      this.$confirm("确定将审核状态恢复为待审核？").then((res) => {
        let url = "/user/coachAttestationRecord/update";
        let data = {
          id: row.id,
          status: 0,
          coach_id: row.coach_id,
          admin_id: this.admin.id,
          admin_name: this.admin.name,
        };
        this.$axios({
          url,
          data,
          method: "post",
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({ message: "成功" });
            this.getList();
          } else {
            this.$message({ message: res.data.message });
          }
        });
      });
    },
    refuse() {
      if (!this.remark) {
        this.$message("请填写拒绝理由");
        return;
      }
      let url = "/user/coachAttestationRecord/update";
      let data = {
        id: this.coachInfo.id,
        status: 2,
        remark: this.remark,
        admin_id: this.admin.id,
        admin_name: this.admin.name,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.getList();
        } else {
          this.$message({ message: res.data.message });
        }
        this.dialogVisible = false;
      });
    },
    GetListPageChild() {
      let url = this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "体育宝",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },
    toDetails(row) {
      this.$router.push({
        name: "coachCardDetails",
        query: { id: row.id, coach_id: row.coach_id },
      });
    },
    //更新状态
    update(row, status) {
      let url = "user/coachAttestationRecord/update";
      let data = {
        id: row.id,
        status,
        admin_id: this.admin.id,
        admin_name: this.admin.name,
      };

      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.getList();
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //列表
    getList() {
      this.is_loading = true;
      let url = "/user/coachAttestationRecord/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.status,
        coach_name: this.name || null,
        coach_phone: this.phone || null,
        admin_id: this.admin_id || null,
        ...this.cityParam,
      };
      if (this.teaching_categories) {
        params.teaching_categories = this.teaching_categories;
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          if (this.status == 0) {
            this.timeShow = false;
          } else {
            this.timeShow = true;
          }
          this.DataList = res.data.data.rows;

          this.Total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>
      
<style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;

  .el-form-item {
    width: 40%;
  }
}
</style>
      